import React, { useContext } from 'react';
import { Switch, Route, Redirect, useParams } from 'react-router-dom';

import { Context } from './Context/AuthContext';

import Login from './pages/Login';
import Create from './pages/Create';
import Dashboard from './pages/Dashboard';
import Logout from './pages/Logout';

function CustomRoute({ isPrivate, ...rest }) {
  const { loading, authenticated } = useContext(Context);

  if (loading) {
    return <h1>Loading...</h1>;
  }

  if (isPrivate && !authenticated) {
    return <Redirect to="/" />
  }

  return <Route {...rest} />;
}

export default function Routes() {
  return (
    <Switch>
      <CustomRoute exact path="/" component={Login} />
      <CustomRoute path="/cadastrar" component={Create} />
      <CustomRoute isPrivate path="/painel" component={Dashboard} />
      {/* <CustomRoute isPrivate path="/pedidos" component={Order} />
      <CustomRoute isPrivate path="/novo-pedido" component={newOrder} />
      <CustomRoute isPrivate path="/detalhe-pedido/:id" component={viewOrder} /> */}
      <CustomRoute path="/sair" component={Logout} />
      {/* <CustomRoute path="/landing" component={Landing} />
      <CustomRoute path="/profile" component={Profile} /> */}
    </Switch>
  );
}