import React, {useContext, useState, useEffect } from 'react';
import { useForm, Controller } from "react-hook-form";
import InputMask from "react-input-mask";
import "./style.css";

import { Context } from '../../Context/AuthContext';

// Messages
const required = "Este campo é obrigatório";
const maxLength = "Você ultrapassou o limite máximo de caracteres";


// Error Component
const errorMessage = error => {
  return <div className="invalid-feedback">{error}</div>;
};

export default function Login() {
  const { authenticated, handleLogin, errorLogin } = useContext(Context);
  const [messageRequeauthenticatedst, setMessageRequest] = useState("");
  const [expireAcess, setExpireAcess] = useState("");
  const { register, handleSubmit, errors, control } = useForm();


  const onSubmit = async data => {
    handleLogin(data);
  };
  return (
    <div className="backgroundImage">
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-1 shadow-lg bg-yellow-600 border-0 bg-opacity-80">
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  {
                    expireAcess !== "" ? <div className='text-white text-center bg-red-400 p-2 mb-2'>{expireAcess}</div> : ""
                  }
                <div className="statusRequest">{errorLogin}</div>
                  <h6 className="text-white text-md font-bold">
                    Faça seu Login
                  </h6>
                </div>
              </div>
              <div className="flex-auto px-4 lg:px-10 py-4 pt-0">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="relative w-full mb-3">
                    <input
                      type="email"
                      className="px-3 py-3 placeholder-gray-700 text-gray-700 bg-white text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                      placeholder="Email"
                      name="email"
                      required
                      ref={register({ required: true })}
                    />
                    <div>
                      {errors.email &&
                      errors.email.type === "required" &&
                      errorMessage(required)}
                    </div>
                  </div>

                  <div className="relative w-full mb-3">
                    <input
                      type="password"
                      className="px-3 py-3 placeholder-gray-700 text-gray-700 bg-white text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                      placeholder="Senha"
                      name="password"
                      required
                      ref={register({ required: true, minLength: 6 })}
                    />
                    <div>
                      {errors.password &&
                      errors.password.type === "required" &&
                      errorMessage(required)}
                      {errors.password &&
                       errors.password.type === "maxLength" &&
                      errorMessage(maxLength)}
                    </div>
                  </div>

                  <div className="text-center mt-6">
                    <button
                      className="bg-red-800 text-white active:bg-gray-200 text-sm font-bold uppercase px-6 py-3 shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="submit"
                    >
                      Entrar
                    </button>
                  </div>
                </form>
                <div className="w-full text-center">
                <a
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  className="text-gray-900"
                >
                  <small>Esqueceu sua Senha?</small>
                </a>
              </div>
              </div>
            </div>
            <div className="relative flex flex-col min-w-0 break-words w-full shadow-lg bg-yellow-600 border-0 bg-opacity-80">
              <div className="p-4 text-center text-black"><a href="/cadastrar">Não tem conta? Cadastre-se</a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}