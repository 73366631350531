import React, { useState, useEffect } from 'react';
import { Cookies } from 'react-cookie';

import Navbar from "components/Navbar.js";
import Sidebar from "components/Sidebar.js";
import Header from "components/Header.js";
import Footer from "components/Footer.js";

import history from '../../history';

const cookies = new Cookies();

function Logout() {

  useEffect(() => {
    cookies.remove('token');
    history.push('/');
  }, []);

  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-gray-200">
        <Navbar />
        {/* Header */}
        <Header />

        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
            <div className="rounded-t bg-white mb-0 px-6 py-6">
                Saindo...
            </div>
            <Footer />
        </div>
      </div>
    </>
  );
}

export default Logout;
